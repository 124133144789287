/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { ensureCurrentUser } from '../../../../util/data';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';

import {
  AvatarLarge,
  ExternalLink,
  InlineTextButton,
  NamedLink,
  NotificationBadge
} from '../../../../components';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCartCount } from '../../../CartPage/CartPage.duck';
import css from './TopbarMobileMenu.module.css';

const CustomLinkComponent = ({ linkConfig, currentPage }) => {
  const { group, text, type, href, route } = linkConfig;
  const getCurrentPageClass = page => {
    const hasPageName = name => currentPage?.indexOf(name) === 0;
    const isCMSPage = pageId => hasPageName('CMSPage') && currentPage === `${page}:${pageId}`;
    const isInboxPage = tab => hasPageName('InboxPage') && currentPage === `${page}:${tab}`;
    const isCurrentPage = currentPage === page;

    return isCMSPage(route?.params?.pageId) || isInboxPage(route?.params?.tab) || isCurrentPage
      ? css.currentPage
      : null;
  };

  // Note: if the config contains 'route' keyword,
  // then in-app linking config has been resolved already.
  if (type === 'internal' && route) {
    // Internal link
    const { name, params, to } = route || {};
    const className = classNames(css.navigationLink, getCurrentPageClass(name));
    return (
      <NamedLink name={name} params={params} to={to} className={className}>
        <span className={css.menuItemBorder} />
        {text}
      </NamedLink>
    );
  }
  return (
    <ExternalLink href={href} className={css.navigationLink}>
      <span className={css.menuItemBorder} />
      {text}
    </ExternalLink>
  );
};
const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

const handleLanguageChange = (language) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem("language", language);
    window.location.reload();
  }
};

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    customLinks,
    onLogout,
    merchantMaybe
  } = props;

  const location = useLocation();
  const state = useSelector(state => state);
  const isOnCartPage = location.pathname === '/cart';
  const cartCount = getCartCount(state, isOnCartPage) || 0;

  const user = ensureCurrentUser(currentUser);
  const selectedLanguage = typeof window !== 'undefined' && localStorage.getItem("language");

  const extraLinks = customLinks.map(linkConfig => {
    return (
      <CustomLinkComponent
        key={linkConfig.text}
        linkConfig={linkConfig}
        currentPage={currentPage}
      />
    );
  });

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isInboxPage = currentPage?.indexOf('InboxPage') === 0 && page?.indexOf('InboxPage') === 0;
    return currentPage === page || isAccountSettingsPage || isInboxPage ? css.currentPage : null;
  };
  const inboxTab = currentUserHasListings ? 'sales' : 'orders';

  const { userType } = currentUser?.attributes?.profile?.publicData || {};
  const isMerchant = userType === "provider";

  const languageButtons = (<div>
    <div className={css.customerLanguage}>
      <span className={classNames(css.languageText, selectedLanguage === "English" ? css.activeLanguage : null)}
        onClick={() => handleLanguageChange("English")}
      >
        English</span>
      <span className={css.languageText}> | </span>
      <span className={classNames(css.languageText, selectedLanguage === "Arabic" ? css.activeLanguage : null)}
        onClick={() => handleLanguageChange("Arabic")}
      >
        اللغة الانجليزية</span>
      {/* {isMerchant ? <NamedLink className={css.notificationLink} name="NotificationPage">
        <div className={css.notificationIcon}>
          <span>
            <BrandingIconCard type="notification" />
          </span>
        </div>
      </NamedLink> : null} */}
    </div>

  </div>
  )

  const customerMenus = (<div className={css.accountLinksWrapper}>
    <NamedLink
      className={css.navigationLink}
      name="AlgoliaSearchPage"
      params={{ listingType: 'deals' }}
    >
      <FormattedMessage id="TopbarDesktop.deals" />
    </NamedLink>
    <NamedLink
      className={css.navigationLink}
      name="AlgoliaSearchPage"
      params={{ listingType: 'services' }}
    >
      <FormattedMessage id="TopbarDesktop.services" />
    </NamedLink>
    <NamedLink
      className={css.navigationLink}
      name="AlgoliaSearchPage"
      params={{ listingType: 'products' }}
    >
      <FormattedMessage id="TopbarDesktop.products" />
    </NamedLink>
    <span className={css.navigationLink}>
      <FormattedMessage id="TopbarDesktop.about" />
    </span>
    {isAuthenticated ? <NamedLink className={css.navigationLink} name="WishlistPage">
      <FormattedMessage id="TopbarDesktop.wishlist" />
    </NamedLink> : null}

    {/* /// */}
    {!isAuthenticated ? (<NamedLink
      className={css.navigationLink}
      name="LandingPageMerchant"
      params={{ userType: 'provider' }}
    >
      <FormattedMessage id="TopbarDesktop.becomeMerchant" />
    </NamedLink>)
      : null}

    {isAuthenticated ? <>
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
        name="ProfileSettingsPage"
      >
        <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
      </NamedLink>
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
        name="AccountSettingsPage"
      >
        <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
      </NamedLink>

      <NamedLink
        className={classNames(css.navigationLink, css.orderLink, (location?.pathname.includes("/sales/bookings") || location?.pathname.includes("/orders/bookings")) && css.activeLink)}
        name="InboxNewPage"
        params={{ tab: `${isMerchant ? 'sales' : 'orders'}`, state: 'bookings' }}
      >
        <FormattedMessage id="TopbarDesktop.bookings" />
      </NamedLink>
      <NamedLink
        className={classNames(css.navigationLink, css.orderLink, (location?.pathname.includes("/sales/orders") || location?.pathname.includes("/orders/orders")) && css.activeLink)}
        name="InboxNewPage"
        params={{ tab: `${isMerchant ? 'sales' : 'orders'}`, state: 'orders' }}
      >
        <FormattedMessage id="TopbarDesktop.orders" />
      </NamedLink>

      {/* <NamedLink
        className={classNames(
          css.navigationLink,
          currentPageClass('CartPage'),
        )}
        name="CartPage"
      >
        <FormattedMessage id="TopbarDesktop.yourCartLink" />
        <span className={css.cartCount}> {cartCount}</span>
      </NamedLink> */}
    </> : null}

  </div>)

  if (!isAuthenticated) {
    const signup = (isMerchant || merchantMaybe ?
      <NamedLink className={css.startFreeButton} name="SignupForUserTypePage" params={{ userType: 'provider' }}>
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.startForFree" />
      </NamedLink>
      :
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>)
      ;

    const login = (
      isMerchant || merchantMaybe ?
        <NamedLink name="LoginForUserTypePage" className={css.signinButton} params={{ userType: 'provider' }}>
          <span className={css.signupLabel}>
            <FormattedMessage id="TopbarDesktop.login1" />
          </span>
        </NamedLink>
        :
        <NamedLink name="LoginPage" className={css.loginLink}>
          <FormattedMessage id="TopbarMobileMenu.loginLink" />
        </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );


    return (
      <div className={classNames(css.root, arabicLanguage && css.arabicDirection)}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>

          <div className={css.customLinksWrapper}>{extraLinks}</div>

          {/* <div className={css.spacer} /> */}
          {languageButtons}

          {(isMerchant || merchantMaybe) ?
            <div className={css.accountLinksWrapper}>
              <NamedLink className={css.navigationLink} name="LandingPageMerchant" >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.home" />
              </NamedLink>
              <NamedLink className={css.navigationLink} name="ProfileSettingsPage">
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.forCustomers" />
              </NamedLink>
              <NamedLink className={css.navigationLink} name="AccountSettingsPage">
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.merchants" />
              </NamedLink>
              <NamedLink className={css.navigationLink} name="NewDealPage">
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.features" />
              </NamedLink>
            </div>
            : customerMenus}
        </div>
        {/* <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div> */}
      </div>
    );
  }

  return (
    <div className={classNames(css.root, arabicLanguage && css.arabicDirection)}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        {languageButtons}

        {isMerchant ?
          <div className={css.accountLinksWrapper}>
            <NamedLink
              className={css.navigationLink}
              name="CalenderPage"
            >
              <FormattedMessage id="TopbarDesktop.calendar" />
            </NamedLink>

            <NamedLink
              className={css.navigationLink}
              name="ViewListingsPage"
              params={{ listingType: 'deal' }}
            >
              <FormattedMessage id="TopbarDesktop.deals" />
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, css.orderLink, (location?.pathname.includes("/sales/orders") || location?.pathname.includes("/orders/orders")) && css.activeLink)}
              name="InboxNewPage"
              params={{ tab: 'sales', state: 'orders' }}
            >
              <FormattedMessage id="TopbarDesktop.orders" />
            </NamedLink>
            <NamedLink
              className={css.navigationLink}
              name="ViewListingsPage"
              params={{ listingType: 'service' }}
            >
              <FormattedMessage id="TopbarDesktop.services" />
            </NamedLink>
            <NamedLink
              className={css.navigationLink}
              name="ViewListingsPage"
              params={{ listingType: 'product' }}
            >
              <FormattedMessage id="TopbarDesktop.products" />
            </NamedLink>
            <NamedLink className={css.navigationLink} name="MoreActionPage">
              <FormattedMessage id="TopbarDesktop.more" />
            </NamedLink>
            <NamedLink className={css.navigationLink} name="NotificationPage">
              <FormattedMessage id="TopbarDesktop.notification" />
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
              name="ProfileSettingsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
            </NamedLink>

            {/* <NamedLink
              className={classNames(css.inbox, currentPageClass(`InboxPage:${inboxTab}`))}
              name="InboxPage"
              params={{ tab: inboxTab }}
            >
              <FormattedMessage id="TopbarMobileMenu.inboxLink" />
              {notificationCountBadge}
            </NamedLink> */}
            <NamedLink
              className={classNames(css.navigationLink, css.orderLink, (location?.pathname.includes("/sales/bookings") || location?.pathname.includes("/orders/bookings")) && css.activeLink)}
              name="InboxNewPage"
              params={{ tab: `${isMerchant ? 'sales' : 'orders'}`, state: 'bookings' }}
            >
              <FormattedMessage id="TopbarDesktop.bookings" />
            </NamedLink>
            {/* <NamedLink
              className={classNames(css.navigationLink, currentPageClass('InboxNewPage'))}
              name="InboxNewPage"
              params={{ tab: `${isMerchant ? 'sales' : 'orders'}`, state: 'orders' }}
            >
              <FormattedMessage id="TopbarDesktop.orders" />
            </NamedLink> */}
            {/* <NamedLink
              className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
            </NamedLink> */}

          </div>
          :
          customerMenus
        }
        <div className={css.customLinksWrapper}>{extraLinks}</div>
        <div className={css.spacer} />
      </div>
      {/* <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
        <br />
        <NamedLink className={css.createNewListingLink} name="NewDealPage">
          <FormattedMessage id="TopbarMobileMenu.newDealLink" />
        </NamedLink>
      </div> */}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
