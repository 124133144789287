import classNames from 'classnames';
import { bool, func, number, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import logoImage from '../../../../assets/logo.png'
import {
  Avatar,
  BrandingIconCard,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
  ResponsiveImage,
} from '../../../../components';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';
import css from './TopbarDesktop.module.css';
import { getCartCount } from '../../../CartPage/CartPage.duck';
import { useSelector } from 'react-redux';
import OneSignal from 'react-onesignal';
import IconCollection from '../../../../components/IconCollection/IconCollection';
import { Icon } from '../../../../components/IconAdd/IconAdd.example';
import { useLocation } from 'react-router-dom';

/**
 * Component for the "Signup as Customer" link
 */
const SignupLinkCustomer = () => (
  <NamedLink
    name="SignupForUserTypePage"
    params={{ userType: 'customer' }}
    className={css.topbarLink}
  >
    <span className={css.topbarLinkLabel}>
      <FormattedMessage id="TopbarDesktop.signupCustomer" />
    </span>
  </NamedLink>
);

/**
 * Component for the "Signup as Merchant" link
 */
const SignupLinkMerchant = () => (
  <NamedLink
    name="SignupForUserTypePage"
    params={{ userType: 'provider' }}
    className={css.topbarLink}
  >
    <span className={css.topbarLinkLabel}>
      <FormattedMessage id="TopbarDesktop.signupMerchant" />
    </span>
  </NamedLink>
);

/**
 * Component for the "Login" link
 */
const LoginLink = () => (
  <NamedLink name="LoginPage" className={css.signinButton}>
    <span className={css.signupLabel}>
      <FormattedMessage id="TopbarDesktop.login1" />
    </span>
  </NamedLink>
);

const LoginLinkMerchant = () => (
  <NamedLink name="LoginForUserTypePage" className={css.signinButton} params={{ userType: 'provider' }}>
    <span className={css.signupLabel}>
      <FormattedMessage id="TopbarDesktop.login1" />
    </span>
  </NamedLink>
);

/**
 * Component for the "Inbox" link with notification dot
 */
const InboxLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  return (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

/**
 * Component for the profile menu with options for settings and logout
 */
const ProfileMenu = ({ currentPage, currentUser, onLogout }) => {
  /**
   * Determines the class name for the active menu item based on the current page
   */
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const { userType, profileListingId } = currentUser?.attributes?.profile?.publicData || {};
  const currentUserId = currentUser?.id?.uuid;
  const isProvider = userType === "provider";
  const shouldRedirectToMerchantPage = currentUserId && isProvider && profileListingId;

  const location = useLocation();
  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {/* <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key={shouldRedirectToMerchantPage ? "ViewBusinessPage" : "ProfilePage"}>
          <NamedLink
            className={classNames(css.menuLink, currentPageClass(shouldRedirectToMerchantPage ? "ViewBusinessPage" : "ProfilePage"))}
            name={shouldRedirectToMerchantPage ? "ViewBusinessPage" : currentUserId ? "ProfilePage" : "ProfileSettingsPage"}
            params={shouldRedirectToMerchantPage ? { listingType: "business-profile", id: profileListingId } : currentUserId ? { id: currentUserId } : {}}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxBookingPage">
          <NamedLink
            className={classNames(css.menuLink, (location?.pathname.includes("/sales/bookings") || location?.pathname.includes("/orders/bookings")) && css.activeLink)}
            name="InboxNewPage"
            params={{ tab: `${isProvider ? 'sales' : 'orders'}`, state: 'bookings' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.bookings" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxOrderPage">
          <NamedLink
            className={classNames(css.menuLink, (location?.pathname.includes("/sales/orders") || location?.pathname.includes("/orders/orders")) && css.activeLink)}
            name="InboxNewPage"
            params={{ tab: `${isProvider ? 'sales' : 'orders'}`, state: 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.orders" />
          </NamedLink>
        </MenuItem>

        {/* <MenuItem key="CartPage">
          <NamedLink
            className={classNames(
              css.menuLink,
              currentPageClass('CartPage'),
              isProvider && css.hidden
            )}
            name="CartPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourCartLink" />
          </NamedLink>
        </MenuItem> */}

        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const OfflineCustomerProfileMenu = ({ currentPage, currentUser, onLogout }) => {
  /**
   * Determines the class name for the active menu item based on the current page
   */
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const { userType } = currentUser?.attributes?.profile?.publicData || {};
  const isProvider = userType === "provider";

  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>

      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="SignupForUserTypePage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('SignupForUserTypePage'))}
            name="SignupForUserTypePage"
            params={{ userType: 'customer' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.signup" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="LoginForUserTypePage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('LoginForUserTypePage'))}
            name="LoginForUserTypePage"
            params={{ userType: 'customer' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.login" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

/**
 * The main topbar component for desktop layout
 */

const CartLink = ({ notificationCount }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  return (
    <NamedLink className={css.topbarLink} name="CartPage">
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.cart" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

const TopbarDesktop = ({
  className,
  config,
  customLinks,
  currentUser,
  currentPage,
  rootClassName,
  currentUserHasListings,
  notificationCount,
  intl,
  isAuthenticated,
  onLogout,
  onSearchSubmit,
  initialSearchFormValues,
  merchantMaybe,
}) => {
  const [mounted, setMounted] = useState(false);
  const state = useSelector(state => state);
  const { publicData } = currentUser?.attributes?.profile || {};

  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";
  const {
    userType,
    wishlist_deal_ids = [],
    wishlist_product_ids = [],
    wishlist_service_ids = [],
    profileListingId,
  } = publicData || {};


  const currentUserId = currentUser?.id?.uuid;
  const isProvider = userType === "provider";
  const shouldRedirectToMerchantPage = currentUserId && isProvider && profileListingId;

  const location = useLocation();
  const isOnCartPage = location.pathname === '/cart';

  useEffect(() => {
    // Ensure this code runs only on the client side
    if (
      typeof window !== 'undefined' &&
      currentUser?.attributes?.profile?.publicData?.userType === 'provider'
    ) {
      OneSignal.init({
        appId: '0840a204-f095-4160-804d-5e818fbc7665',
        // You can add other initialization options here
        notifyButton: {
          enable: true,
        },
        allowLocalhostAsSecureOrigin: true,
        // Uncomment the below line to run on localhost. See: https://documentation.onesignal.com/docs/local-testing
        // allowLocalhostAsSecureOrigin: true
      });
      if (currentUser?.id?.uuid) {
        OneSignal.login(currentUser?.id?.uuid);
      } else {
        OneSignal.logout();
      }
    }
  }, [currentUser?.id?.uuid]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  // Determine if the search form should take up space
  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || css.root, className, arabicLanguage && css.arabicDirection);

  // Conditional rendering based on authentication status
  const inboxLinkMaybe = authenticatedOnClientSide ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu currentPage={currentPage} currentUser={currentUser} onLogout={onLogout} />
  ) : null;

  const offlineProfileMenuMaybe = !authenticatedOnClientSide ? (
    <OfflineCustomerProfileMenu currentPage={currentPage} currentUser={currentUser} onLogout={onLogout} />
  ) : null;

  const signupLinkCustomerMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLinkCustomer />;
  const signupLinkMerchantMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLinkMerchant />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;
  const LoginLinkMerchantMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLinkMerchant />;

  const cartCount = getCartCount(state, isOnCartPage) || 0;

  // Get Wishlist count
  const wishlistCount = wishlist_deal_ids?.length + wishlist_product_ids?.length + wishlist_service_ids?.length;

  const cartLinkMaybe = authenticatedOnClientSide ? (
    <CartLink notificationCount={cartCount} />
  ) : null;

  const [selectedLanguage, setSelectedLanguage] = useState();

  useEffect(() => {
    setMounted(true);
    const storedLanguage = typeof window !== 'undefined' && localStorage.getItem('language');
    if (typeof window !== 'undefined' && !localStorage.getItem("rendered")) {
      localStorage.setItem('language', "English")
      localStorage.setItem("rendered", "English")
      setLanguage("English")
    }
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);
  const setLanguage = (lg) => {
    typeof window != "undefined" && [localStorage.setItem('language', lg), localStorage.setItem('rendered', lg)]
    typeof window != "undefined" && window.location.reload();
  }
  const handleLanguageChange = (lg) => {
    setLanguage(lg);
    setSelectedLanguage(lg);
  };

  return (
    <nav className={classes}>
      <div>
        {userType === 'provider' || merchantMaybe ? (
          <>
            {isAuthenticated ? (
              <div className={css.afterLoginWrapper}>
                <div className={css.loginWrapper}>
                  <div className={css.headerLeft}>
                    <div className={css.logoWrapper}>
                      <NamedLink className={css.link} name="LandingPage">
                        <IconCollection name="vendor_logo" />
                      </NamedLink>
                    </div>
                    <div className={css.menuList}>
                      <div className={css.menuNames}>
                        <NamedLink className={css.link} name="LandingPage">
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.home" />
                        </NamedLink>
                        <NamedLink
                          className={css.link}
                          name="CalenderPage"
                        >
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.calendar" />
                        </NamedLink>

                        <NamedLink
                          className={css.link}
                          name="ViewListingsPage"
                          params={{ listingType: 'deal' }}
                        >
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.deals" />
                        </NamedLink>
                        <NamedLink
                          className={css.link}
                          name="InboxNewPage"
                          params={{ tab: 'sales', state: 'orders' }}
                        >
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.orders" />
                        </NamedLink>
                        <NamedLink
                          className={css.link}
                          name="ViewListingsPage"
                          params={{ listingType: 'service' }}
                        >
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.services" />
                        </NamedLink>
                        <NamedLink
                          className={css.link}
                          name="ViewListingsPage"
                          params={{ listingType: 'product' }}
                        >
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.products" />
                        </NamedLink>
                        <NamedLink className={css.link} name="MoreActionPage">
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.more" />
                        </NamedLink>
                      </div>
                    </div>
                  </div>
                  <div className={css.headerRight}>
                    <div className={css.customerLanguage}>
                      <span className={classNames(css.languageText, selectedLanguage === "English" ? css.activeLanguage : null)}
                        onClick={() => handleLanguageChange("English")}
                      >
                        English</span>
                      <span className={css.languageText}> | </span>
                      <span className={classNames(css.languageText, selectedLanguage === "Arabic" ? css.activeLanguage : null)}
                        onClick={() => handleLanguageChange("Arabic")}
                      >
                        اللغة الانجليزية</span>
                    </div>
                    <NamedLink
                      className={css.link}
                      name={shouldRedirectToMerchantPage ? "ViewBusinessPage" : currentUserId ? "ProfilePage" : "ProfileSettingsPage"}
                      params={shouldRedirectToMerchantPage ? { listingType: "business-profile", id: profileListingId } : currentUserId ? { id: currentUserId } : {}}
                    >
                      <span>
                        <BrandingIconCard type="setting" />
                      </span>
                    </NamedLink>
                    <NamedLink className={css.link} name="NotificationPage">
                      {/* <span className={css.menuItemBorder} />
                      <FormattedMessage id="TopbarDesktop.more" /> */}
                      <div className={css.notificationIcon}>
                        <span>
                          <BrandingIconCard type="notification" />
                        </span>
                      </div>
                    </NamedLink>
                    <div className={css.profileIcon}>{profileMenuMaybe}</div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={css.headerWrapper}>
                <div className={css.leftBox}>
                  <div className={css.logoIcon}>
                    {/* <LinkedLogo
                      className={css.logoLink}
                      layout="desktop"
                      alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
                      linkToExternalSite={config?.topbar?.logoLink}
                      merchantMaybe={merchantMaybe}
                    /> */}
                    <NamedLink name="LandingPageMerchant">
                      <IconCollection name="vendor_logo" />
                    </NamedLink>

                  </div>
                  <div className={css.centerHeader}>
                    <NamedLink className={css.link} name="LandingPageMerchant" >
                      <span className={css.menuItemBorder} />
                      <FormattedMessage id="TopbarDesktop.home" />
                    </NamedLink>
                    <NamedLink className={css.link} name="LandingPageMerchant">
                      <span className={css.menuItemBorder} />
                      <FormattedMessage id="TopbarDesktop.forCustomers" />
                    </NamedLink>
                    <NamedLink className={css.link} name="LandingPageMerchant">
                      <span className={css.menuItemBorder} />
                      <FormattedMessage id="TopbarDesktop.merchants" />
                    </NamedLink>
                    <NamedLink className={css.link} name="LandingPageMerchant">
                      <span className={css.menuItemBorder} />
                      <FormattedMessage id="TopbarDesktop.features" />
                    </NamedLink>
                  </div>
                </div>
                {inboxLinkMaybe}
                {/* {cartLinkMaybe} */}

                {/* {signupLinkCustomerMaybe}
                {signupLinkMerchantMaybe} */}
                <div className={css.rightBarHeading}>
                  {LoginLinkMerchantMaybe}
                  <NamedLink className={css.startFreeButton} name="SignupForUserTypePage" params={{ userType: 'provider' }}>
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarDesktop.startForFree" />
                  </NamedLink>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className={css.customerHeader}>
            <div className={css.customerWrapper}>
              <div className={css.customerLeftBox}>
                <NamedLink className={css.link} name="LandingPage">
                  <FormattedMessage id="TopbarDesktop.home" />
                </NamedLink>
                {/* <NamedLink className={css.link} name="LandingPage">
                  <FormattedMessage id="TopbarDesktop.bookings" />
                </NamedLink> */}
                <NamedLink
                  className={css.link}
                  name="AlgoliaSearchPage"
                  params={{ listingType: 'deals' }}
                >
                  <FormattedMessage id="TopbarDesktop.deals" />
                </NamedLink>
                <NamedLink
                  className={css.link}
                  name="AlgoliaSearchPage"
                  params={{ listingType: 'services' }}
                >
                  <FormattedMessage id="TopbarDesktop.services" />
                </NamedLink>
                <NamedLink
                  className={css.link}
                  name="AlgoliaSearchPage"
                  params={{ listingType: 'products' }}
                >
                  <FormattedMessage id="TopbarDesktop.products" />
                </NamedLink>
                <span className={css.link}>
                  <FormattedMessage id="TopbarDesktop.about" />
                </span>
                {/* <NamedLink className={css.link} name="NotificationPage">
                  <FormattedMessage id="TopbarDesktop.about" />
                </NamedLink> */}
                {/* /// */}
                {!isAuthenticated ? (<NamedLink
                  className={css.link}
                  name="LandingPageMerchant"
                  params={{ userType: 'provider' }}
                >
                  <FormattedMessage id="TopbarDesktop.becomeMerchant" />
                </NamedLink>)
                  : null}
                {/* ////// */}
              </div>
              <div className={css.customerCenterBox}>
                <NamedLink className={css.link} name="LandingPage">
                  {/* <BrandingIconCard type="bluelogo" /> */}
                  {/* <ResponsiveImage className={css.logoImage} localImageSrc={logoImage} variants="custom-image" alt="logo" /> */}
                  <IconCollection name="customer_logo" />
                </NamedLink>
              </div>
              <div className={css.customerRightBox}>
                <div className={css.userProfile}>
                  {profileMenuMaybe}
                  {offlineProfileMenuMaybe}
                </div>

                <div className={css.customerLanguage}>
                  <span className={classNames(css.languageText, selectedLanguage === "English" ? css.activeLanguage : null)}
                    onClick={() => handleLanguageChange("English")}
                  >
                    English</span>
                  <span className={css.languageText}> | </span>
                  <span className={classNames(css.languageText, selectedLanguage === "Arabic" ? css.activeLanguage : null)}
                    onClick={() => handleLanguageChange("Arabic")}
                  >
                    اللغة الانجليزية</span>
                </div>
                <NamedLink className={css.wishlistPage} name="WishlistPage">
                  <IconCollection name="wishlist_icon" />
                  <span className={css.cartCount}>{wishlistCount || 0}</span>
                </NamedLink>
                {/* <NamedLink className={''} name="CartPage">
                  <div className={css.cartLogo}>
                    <BrandingIconCard type="cart" />
                    <span className={css.cartCount}>{cartCount}</span>
                  </div>
                </NamedLink> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

// Default prop values
TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

// Prop type validation
TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
